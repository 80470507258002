<template>
  <div w="full" relative bg="white" flex="1">
    <div class="relative top-0">
      <div class="absolute h-120 w-full" flex="~ justify-center items-center">
        <div
          v-if="bgColor"
          w="full"
          h="full"
          :style="{
            background: 'center center no-repeat',
            backgroundSize: 'cover',
            backgroundColor: 'var(--tc-color-bg-index)',
            backgroundImage: `url(${pageStyle.pcBgUrl})`,
          }"
        >
        </div>
        <template v-else>
          <img w="full" h="full" src="~/assets/images/home/pc_bg.png" />
          <div top="0" class="absolute" w="full" h="full" style="background: linear-gradient(180deg, rgba(14, 70, 56, 0.4) 0%, rgba(14, 70, 56, 0.24) 19.69%)" />
        </template>
      </div>

      <van-sticky :offset-top="0" :z-index="2000">
        <div :bg="isArriveTop ? 'taransparent' : 'white'" shadow="[0_4px_12px_0_#0E46380A]">
          <DesktopNavBar :show-search="false" :theme="isArriveTop ? 'white' : 'green'" />
        </div>
      </van-sticky>

      <div mt="16" mb="12" class="relative" text="center white" px="6" font="500">
        <h1 class="whitespace-pre-wrap" leading="12" text="4xl" font="400" :style="{ color: 'var(--tc-color-title-index)' }">
          {{ t(pageTitle) }}
        </h1>
        <h2 v-if="subTitle" class="whitespace-pre-wrap" mt="6" text="2xl" font="300" :style="{ color: 'var(--tc-color-subtitle-index)' }">
          {{ t(subTitle) }}
        </h2>
      </div>

      <!-- 搜索框 -->
      <DesktopSearchDrawer v-model:show="searchShow" w="175" mx="auto">
        <div class="relative" mt="12" bg="white" pl="6" pr="1.5" h="16" flex="~ items-center" rounded="15" py="1.5">
          <NuxtIcon name="attr-icon:search" class="mr-2" text="2xl" />
          <input
            v-model="keywords" flex="1" text="base" :placeholder="t('key.search.result.input.search.placeholder')"
            class="placeholder:text-#B2B2B2 placeholder:font-300" type="search" maxlength="100" pr="2" @search="navigateToSearch" @focus="showTips"
          />
          <div v-if="keywords" w="8" h="8" flex="~ items-center justify-center" mr="3" rounded="full" bg="#F5F5F5" class="azgo-haptics-feedback" @click="clearSearch">
            <NuxtIcon name="my-icon:close" class="#121212 azgo-hover-feedback text-3" />
          </div>
          <div
            cursor="pointer" hover="opacity-80" class="azgo-haptics-feedback"
            :style="{ background: 'var(--tc-color-bg-index)' }" px="8" h="full" rounded="15"
            flex="~ items-center justify-center" text="white base" font="500"
            @click="navigateToSearch"
          >
            {{ t('key.attractions.search.todo') }}
          </div>
        </div>
      </DesktopSearchDrawer>
    </div>

    <div max-w="300" m="x-auto" pt="40">
      <!-- 频道 -->
      <BusinessBChannel />

      <!-- 最近浏览 -->
      <DesktopRecentlyViewed />

      <!-- 热门目的地 -->
      <DesktopPopularDestinations />

      <!-- 合作品牌 -->
      <BusinessBPartnerBrands max-w="300" m="x-auto" />

      <!-- 智能推荐 -->
      <DesktopIntelRecommend />
    </div>

    <!-- 如何赚取 -->
    <BusinessBHowToEarn />
  </div>
</template>

<script lang="ts" setup>
import ogImageUrl from '~/assets/logo.png'

const { t } = useI18n()

useSeoMeta({
  title: t('key.attractions.index.title'),
  description: t('key.attractions.index.descrition'),
  ogImageUrl,
})

const searchShow = ref(false)

function showTips() {
  searchShow.value = true
}

const { scrollContainer } = useScrollContainer()
const { searchHandler } = useSearchResult()
const { currentCity } = useCurrentCity()
const keywords = ref(currentCity.value?.cityName || '')

const { y } = useScroll(scrollContainer)
const isArriveTop = computed(() => y.value <= 0)

const { pageTitle, subTitle } = usePartnerConfig()

const commonDataStore = useCommonStore()
const pageStyle = computed(() => {
  const { indexPageStyle } = commonDataStore.commonData?.partnerInfo?.distStyle || {}

  return indexPageStyle ?? {}
})

const bgColor = computed(() => {
  return pageStyle.value?.bgColor || ''
})

function clearSearch() {
  keywords.value = ''
}

function navigateToSearch() {
  searchShow.value = false
  return searchHandler(keywords.value, true)
}
</script>
